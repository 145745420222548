/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTextractTransactionsFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  bank?: ModelStringInput | null,
  isCredit?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  date?: ModelStringInput | null,
  modifiedDate?: ModelStringInput | null,
  formattedDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  entityId?: ModelStringInput | null,
  entityName?: ModelStringInput | null,
  entityType?: ModelStringInput | null,
  fileId?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  processingBank?: ModelStringInput | null,
  additionalText?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  chequeNo?: ModelStringInput | null,
  customerReference?: ModelStringInput | null,
  instrumentCode?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  transactionType?: ModelStringInput | null,
  valueDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractTransactionsFilterInput | null > | null,
  or?: Array< ModelTextractTransactionsFilterInput | null > | null,
  not?: ModelTextractTransactionsFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTextractTransactionsConnection = {
  __typename: "ModelTextractTransactionsConnection",
  items:  Array<TextractTransactions | null >,
  nextToken?: string | null,
};

export type TextractTransactions = {
  __typename: "TextractTransactions",
  id: string,
  amount?: number | null,
  bank?: string | null,
  isCredit?: boolean | null,
  currency?: string | null,
  date?: string | null,
  modifiedDate?: string | null,
  formattedDate?: string | null,
  description?: string | null,
  entityId?: string | null,
  entityName?: string | null,
  entityType?: string | null,
  fileId?: string | null,
  fileName?: string | null,
  companyName?: string | null,
  processingBank?: string | null,
  additionalText?: string | null,
  channel?: string | null,
  chequeNo?: string | null,
  customerReference?: string | null,
  instrumentCode?: string | null,
  reference?: string | null,
  transactionType?: string | null,
  valueDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelFlunaMembersFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  primaryTeam?: ModelFlunaTeamsInput | null,
  lastActive?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  status?: ModelMemberStatusInput | null,
  googleUserCognitoId?: ModelStringInput | null,
  emailUserCognitoId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFlunaMembersFilterInput | null > | null,
  or?: Array< ModelFlunaMembersFilterInput | null > | null,
  not?: ModelFlunaMembersFilterInput | null,
};

export type ModelFlunaTeamsInput = {
  eq?: FlunaTeams | null,
  ne?: FlunaTeams | null,
};

export enum FlunaTeams {
  businessDevelopment = "businessDevelopment",
  engineering = "engineering",
  finance = "finance",
  operations = "operations",
  customerSuccess = "customerSuccess",
  people = "people",
  product = "product",
  legal = "legal",
  external = "external",
}


export type ModelMemberStatusInput = {
  eq?: MemberStatus | null,
  ne?: MemberStatus | null,
};

export enum MemberStatus {
  active = "active",
  inactive = "inactive",
}


export type ModelFlunaMembersConnection = {
  __typename: "ModelFlunaMembersConnection",
  items:  Array<FlunaMembers | null >,
  nextToken?: string | null,
};

export type FlunaMembers = {
  __typename: "FlunaMembers",
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  primaryTeam?: FlunaTeams | null,
  lastActive?: string | null,
  picture?: string | null,
  modules?:  Array<MemberModules | null > | null,
  status?: MemberStatus | null,
  googleUserCognitoId?: string | null,
  emailUserCognitoId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MemberModules = {
  __typename: "MemberModules",
  name?: FlunaModules | null,
  description?: string | null,
  access?: AccessLevels | null,
};

export enum FlunaModules {
  overview = "overview",
  kyc = "kyc",
  mca = "mca",
  lending = "lending",
  statementParser = "statementParser",
  atlas = "atlas",
  trade = "trade",
  permissions = "permissions",
  investorPortal = "investorPortal",
  priceTracker = "priceTracker",
  finance="finance"
}


export enum AccessLevels {
  fullAccess = "fullAccess",
  readOnlyAccess = "readOnlyAccess",
  noAccess = "noAccess",
}


export type CreateUsersInput = {
  id?: string | null,
  name?: string | null,
  email: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelUsersConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUsersConditionInput | null > | null,
  or?: Array< ModelUsersConditionInput | null > | null,
  not?: ModelUsersConditionInput | null,
};

export type Users = {
  __typename: "Users",
  id: string,
  name?: string | null,
  email: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateUsersInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUsersInput = {
  id: string,
};

export type CreateTextractRecordsInput = {
  id?: string | null,
  bankName?: string | null,
  companyName?: string | null,
  country?: string | null,
  currency?: string | null,
  fileName?: string | null,
  processingStatus?: string | null,
  transactionFileName?: string | null,
  uploaderImage?: string | null,
  uploaderName?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTextractRecordsConditionInput = {
  bankName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  country?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  processingStatus?: ModelStringInput | null,
  transactionFileName?: ModelStringInput | null,
  uploaderImage?: ModelStringInput | null,
  uploaderName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractRecordsConditionInput | null > | null,
  or?: Array< ModelTextractRecordsConditionInput | null > | null,
  not?: ModelTextractRecordsConditionInput | null,
};

export type TextractRecords = {
  __typename: "TextractRecords",
  id: string,
  bankName?: string | null,
  companyName?: string | null,
  country?: string | null,
  currency?: string | null,
  fileName?: string | null,
  processingStatus?: string | null,
  transactionFileName?: string | null,
  uploaderImage?: string | null,
  uploaderName?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateTextractRecordsInput = {
  id: string,
  bankName?: string | null,
  companyName?: string | null,
  country?: string | null,
  currency?: string | null,
  fileName?: string | null,
  processingStatus?: string | null,
  transactionFileName?: string | null,
  uploaderImage?: string | null,
  uploaderName?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTextractRecordsInput = {
  id: string,
};

export type CreateTextractEntitiesInput = {
  id?: string | null,
  name?: string | null,
  type?: string | null,
  count?: number | null,
  inflows?: number | null,
  outflows?: number | null,
  average?: number | null,
  country?: string | null,
  currency?: string | null,
  tags?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTextractEntitiesConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  count?: ModelIntInput | null,
  inflows?: ModelFloatInput | null,
  outflows?: ModelFloatInput | null,
  average?: ModelFloatInput | null,
  country?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractEntitiesConditionInput | null > | null,
  or?: Array< ModelTextractEntitiesConditionInput | null > | null,
  not?: ModelTextractEntitiesConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type TextractEntities = {
  __typename: "TextractEntities",
  id: string,
  name?: string | null,
  type?: string | null,
  count?: number | null,
  inflows?: number | null,
  outflows?: number | null,
  average?: number | null,
  country?: string | null,
  currency?: string | null,
  tags?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateTextractEntitiesInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  count?: number | null,
  inflows?: number | null,
  outflows?: number | null,
  average?: number | null,
  country?: string | null,
  currency?: string | null,
  tags?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTextractEntitiesInput = {
  id: string,
};

export type CreateTextractTransactionsInput = {
  id?: string | null,
  amount?: number | null,
  bank?: string | null,
  isCredit?: boolean | null,
  currency?: string | null,
  date?: string | null,
  modifiedDate?: string | null,
  formattedDate?: string | null,
  description?: string | null,
  entityId?: string | null,
  entityName?: string | null,
  entityType?: string | null,
  fileId?: string | null,
  fileName?: string | null,
  companyName?: string | null,
  processingBank?: string | null,
  additionalText?: string | null,
  channel?: string | null,
  chequeNo?: string | null,
  customerReference?: string | null,
  instrumentCode?: string | null,
  reference?: string | null,
  transactionType?: string | null,
  valueDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTextractTransactionsConditionInput = {
  amount?: ModelFloatInput | null,
  bank?: ModelStringInput | null,
  isCredit?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  date?: ModelStringInput | null,
  modifiedDate?: ModelStringInput | null,
  formattedDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  entityId?: ModelStringInput | null,
  entityName?: ModelStringInput | null,
  entityType?: ModelStringInput | null,
  fileId?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  processingBank?: ModelStringInput | null,
  additionalText?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  chequeNo?: ModelStringInput | null,
  customerReference?: ModelStringInput | null,
  instrumentCode?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  transactionType?: ModelStringInput | null,
  valueDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractTransactionsConditionInput | null > | null,
  or?: Array< ModelTextractTransactionsConditionInput | null > | null,
  not?: ModelTextractTransactionsConditionInput | null,
};

export type UpdateTextractTransactionsInput = {
  id: string,
  amount?: number | null,
  bank?: string | null,
  isCredit?: boolean | null,
  currency?: string | null,
  date?: string | null,
  modifiedDate?: string | null,
  formattedDate?: string | null,
  description?: string | null,
  entityId?: string | null,
  entityName?: string | null,
  entityType?: string | null,
  fileId?: string | null,
  fileName?: string | null,
  companyName?: string | null,
  processingBank?: string | null,
  additionalText?: string | null,
  channel?: string | null,
  chequeNo?: string | null,
  customerReference?: string | null,
  instrumentCode?: string | null,
  reference?: string | null,
  transactionType?: string | null,
  valueDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTextractTransactionsInput = {
  id: string,
};

export type CreateFlunaMembersInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  primaryTeam?: FlunaTeams | null,
  lastActive?: string | null,
  picture?: string | null,
  modules?: Array< MemberModulesInput | null > | null,
  status?: MemberStatus | null,
  googleUserCognitoId?: string | null,
  emailUserCognitoId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MemberModulesInput = {
  name?: FlunaModules | null,
  description?: string | null,
  access?: AccessLevels | null,
};

export type ModelFlunaMembersConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  primaryTeam?: ModelFlunaTeamsInput | null,
  lastActive?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  status?: ModelMemberStatusInput | null,
  googleUserCognitoId?: ModelStringInput | null,
  emailUserCognitoId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFlunaMembersConditionInput | null > | null,
  or?: Array< ModelFlunaMembersConditionInput | null > | null,
  not?: ModelFlunaMembersConditionInput | null,
};

export type UpdateFlunaMembersInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  primaryTeam?: FlunaTeams | null,
  lastActive?: string | null,
  picture?: string | null,
  modules?: Array< MemberModulesInput | null > | null,
  status?: MemberStatus | null,
  googleUserCognitoId?: string | null,
  emailUserCognitoId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFlunaMembersInput = {
  id: string,
};

export type CreateWaiverRequestsInput = {
  id?: string | null,
  loanId?: string | null,
  companyId?: string | null,
  paymentId?: string | null,
  currency?: string | null,
  lateFee?: number | null,
  requester?: string | null,
  requestedBy?: string | null,
  requesterImage?: string | null,
  requestNotes?: string | null,
  waiverRequested?: number | null,
  approver?: Array< string | null > | null,
  approvedAt?: string | null,
  approvedBy?: string | null,
  approverImage?: string | null,
  approvalNotes?: string | null,
  waiverApproved?: number | null,
  status?: WaiverStatus | null,
  supportingDocuments?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum WaiverStatus {
  pending = "pending",
  rejected = "rejected",
  approved = "approved",
}


export type ModelWaiverRequestsConditionInput = {
  loanId?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  paymentId?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  lateFee?: ModelFloatInput | null,
  requester?: ModelStringInput | null,
  requestedBy?: ModelStringInput | null,
  requesterImage?: ModelStringInput | null,
  requestNotes?: ModelStringInput | null,
  waiverRequested?: ModelFloatInput | null,
  approver?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  approvedBy?: ModelStringInput | null,
  approverImage?: ModelStringInput | null,
  approvalNotes?: ModelStringInput | null,
  waiverApproved?: ModelFloatInput | null,
  status?: ModelWaiverStatusInput | null,
  supportingDocuments?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWaiverRequestsConditionInput | null > | null,
  or?: Array< ModelWaiverRequestsConditionInput | null > | null,
  not?: ModelWaiverRequestsConditionInput | null,
};

export type ModelWaiverStatusInput = {
  eq?: WaiverStatus | null,
  ne?: WaiverStatus | null,
};

export type WaiverRequests = {
  __typename: "WaiverRequests",
  id: string,
  loanId?: string | null,
  companyId?: string | null,
  paymentId?: string | null,
  currency?: string | null,
  lateFee?: number | null,
  requester?: string | null,
  requestedBy?: string | null,
  requesterImage?: string | null,
  requestNotes?: string | null,
  waiverRequested?: number | null,
  approver?: Array< string | null > | null,
  approvedAt?: string | null,
  approvedBy?: string | null,
  approverImage?: string | null,
  approvalNotes?: string | null,
  waiverApproved?: number | null,
  status?: WaiverStatus | null,
  supportingDocuments?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateWaiverRequestsInput = {
  id: string,
  loanId?: string | null,
  companyId?: string | null,
  paymentId?: string | null,
  currency?: string | null,
  lateFee?: number | null,
  requester?: string | null,
  requestedBy?: string | null,
  requesterImage?: string | null,
  requestNotes?: string | null,
  waiverRequested?: number | null,
  approver?: Array< string | null > | null,
  approvedAt?: string | null,
  approvedBy?: string | null,
  approverImage?: string | null,
  approvalNotes?: string | null,
  waiverApproved?: number | null,
  status?: WaiverStatus | null,
  supportingDocuments?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteWaiverRequestsInput = {
  id: string,
};

export type CreateDocumentsInput = {
  id?: string | null,
  companyId?: string | null,
  key?: string | null,
  bucketName?: string | null,
  fileName?: string | null,
  name?: string | null,
  category?: DocumentCategory | null,
  complianceStatus?: CompanyKYCStatus | null,
  expiresAt?: string | null,
  entityType?: EntityTypes | null,
  entityId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum DocumentCategory {
  Company = "Company",
  Loan = "Loan",
  StatementParser = "StatementParser",
}


export enum CompanyKYCStatus {
  inprogress = "inprogress",
  pending = "pending",
  denied = "denied",
  approved = "approved",
}


export enum EntityTypes {
  Company = "Company",
  Loan = "Loan",
  WaiverRequest = "WaiverRequest",
  StatementParser = "StatementParser",
}


export type ModelDocumentsConditionInput = {
  companyId?: ModelIDInput | null,
  key?: ModelStringInput | null,
  bucketName?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  category?: ModelDocumentCategoryInput | null,
  complianceStatus?: ModelCompanyKYCStatusInput | null,
  expiresAt?: ModelStringInput | null,
  entityType?: ModelEntityTypesInput | null,
  entityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentsConditionInput | null > | null,
  or?: Array< ModelDocumentsConditionInput | null > | null,
  not?: ModelDocumentsConditionInput | null,
};

export type ModelDocumentCategoryInput = {
  eq?: DocumentCategory | null,
  ne?: DocumentCategory | null,
};

export type ModelCompanyKYCStatusInput = {
  eq?: CompanyKYCStatus | null,
  ne?: CompanyKYCStatus | null,
};

export type ModelEntityTypesInput = {
  eq?: EntityTypes | null,
  ne?: EntityTypes | null,
};

export type Documents = {
  __typename: "Documents",
  id: string,
  companyId?: string | null,
  key?: string | null,
  bucketName?: string | null,
  fileName?: string | null,
  name?: string | null,
  category?: DocumentCategory | null,
  complianceStatus?: CompanyKYCStatus | null,
  expiresAt?: string | null,
  entityType?: EntityTypes | null,
  entityId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateDocumentsInput = {
  id: string,
  companyId?: string | null,
  key?: string | null,
  bucketName?: string | null,
  fileName?: string | null,
  name?: string | null,
  category?: DocumentCategory | null,
  complianceStatus?: CompanyKYCStatus | null,
  expiresAt?: string | null,
  entityType?: EntityTypes | null,
  entityId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteDocumentsInput = {
  id: string,
};

export type CreateKPIMetricsInput = {
  id?: string | null,
  day: string,
  investmentData?: InvestmentMetricsInput | null,
  complianceData?: ComplianceMetricsInput | null,
  lendingData?: LendingMetricsInput | null,
  tradeData?: TradeMetricsInput | null,
  MCAData?: MCAMetricsInput | null,
  USDRateUsed?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type InvestmentMetricsInput = {
  totalInvestments?: number | null,
  totalActiveInvestments?: number | null,
  totalWalletBalance?: number | null,
  debtInvestmentAdded?: number | null,
  debtInvestmentWithdrawn?: number | null,
  totalInvestors?: number | null,
  activeInvestors?: number | null,
  investmentSize?: number | null,
  averageInvestmentSize?: number | null,
  weightedAverageCostOfCapital?: number | null,
};

export type ComplianceMetricsInput = {
  numberOfUsersOnPlatform?: number | null,
  numberOfCompaniesOnPlatform?: number | null,
  numberOfOnboardedCompany?: number | null,
  numberOfKycApprovedCompany?: number | null,
  numberOfCompaniesWithBankAccountsConnected?: number | null,
};

export type LendingMetricsInput = {
  totalActiveUSDLoansCount?: number | null,
  totalActiveNGNLoansCount?: number | null,
  totalActiveUSDLoansAmount?: number | null,
  totalActiveNGNLoansAmount?: number | null,
  totalActiveLoansAmount?: number | null,
  totalActiveNonNigerianLoansCount?: number | null,
  totalActiveNigerianLoansCount?: number | null,
  totalActiveNonNigerianLoansAmount?: number | null,
  totalActiveNigerianLoansAmount?: number | null,
  totalCummulativeLoansAmount?: number | null,
  monthlyInterestIncomeAllLoans?: number | null,
  monthlyInterestIncomeLt90Loans?: number | null,
  totalOngoingLoansAmount?: number | null,
  totalLateLoansAmount?: number | null,
  totalPaidLoansAmount?: number | null,
  loansDistributionByCountry?: Array< CountryDistributionInput | null > | null,
  loansDistributionByCompany?: Array< CompanyDistributionInput | null > | null,
  loansDistributionByLoan?: Array< LoanDistributionInput | null > | null,
};

export type CountryDistributionInput = {
  country?: string | null,
  loanAmount?: number | null,
};

export type CompanyDistributionInput = {
  company?: string | null,
  companyId?: string | null,
  loanAmount?: number | null,
};

export type LoanDistributionInput = {
  loanName?: string | null,
  loanId?: string | null,
  renewalId?: string | null,
  applicationId?: string | null,
  loanType?: string | null,
  loanAmount?: number | null,
};

export type TradeMetricsInput = {
  numberOfSuppliers?: number | null,
  numberOfBuyers?: number | null,
  brokerageRevenue?: number | null,
  numberOfTransactions?: number | null,
  numberOfBuyersServed?: number | null,
  grossMerchandisedValue?: number | null,
};

export type MCAMetricsInput = {
  totalMCAOpened?: number | null,
  totalMCAInflowVolume?: number | null,
  totalMCAOutflowVolume?: number | null,
  MCAInflowVolume?: number | null,
  MCAOutflowVolume?: number | null,
  newFxVolume?: number | null,
  USDMCAAcounts?: number | null,
  EURMCAAcounts?: number | null,
  GBPMCAAcounts?: number | null,
};

export type ModelKPIMetricsConditionInput = {
  day?: ModelStringInput | null,
  USDRateUsed?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelKPIMetricsConditionInput | null > | null,
  or?: Array< ModelKPIMetricsConditionInput | null > | null,
  not?: ModelKPIMetricsConditionInput | null,
};

export type KPIMetrics = {
  __typename: "KPIMetrics",
  id: string,
  day: string,
  investmentData?: InvestmentMetrics | null,
  complianceData?: ComplianceMetrics | null,
  lendingData?: LendingMetrics | null,
  tradeData?: TradeMetrics | null,
  MCAData?: MCAMetrics | null,
  USDRateUsed?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type InvestmentMetrics = {
  __typename: "InvestmentMetrics",
  totalInvestments?: number | null,
  totalActiveInvestments?: number | null,
  totalWalletBalance?: number | null,
  debtInvestmentAdded?: number | null,
  debtInvestmentWithdrawn?: number | null,
  totalInvestors?: number | null,
  activeInvestors?: number | null,
  investmentSize?: number | null,
  averageInvestmentSize?: number | null,
  weightedAverageCostOfCapital?: number | null,
};

export type ComplianceMetrics = {
  __typename: "ComplianceMetrics",
  numberOfUsersOnPlatform?: number | null,
  numberOfCompaniesOnPlatform?: number | null,
  numberOfOnboardedCompany?: number | null,
  numberOfKycApprovedCompany?: number | null,
  numberOfCompaniesWithBankAccountsConnected?: number | null,
};

export type LendingMetrics = {
  __typename: "LendingMetrics",
  totalActiveUSDLoansCount?: number | null,
  totalActiveNGNLoansCount?: number | null,
  totalActiveUSDLoansAmount?: number | null,
  totalActiveNGNLoansAmount?: number | null,
  totalActiveLoansAmount?: number | null,
  totalActiveNonNigerianLoansCount?: number | null,
  totalActiveNigerianLoansCount?: number | null,
  totalActiveNonNigerianLoansAmount?: number | null,
  totalActiveNigerianLoansAmount?: number | null,
  totalCummulativeLoansAmount?: number | null,
  monthlyInterestIncomeAllLoans?: number | null,
  monthlyInterestIncomeLt90Loans?: number | null,
  totalOngoingLoansAmount?: number | null,
  totalLateLoansAmount?: number | null,
  totalPaidLoansAmount?: number | null,
  loansDistributionByCountry?:  Array<CountryDistribution | null > | null,
  loansDistributionByCompany?:  Array<CompanyDistribution | null > | null,
  loansDistributionByLoan?:  Array<LoanDistribution | null > | null,
};

export type CountryDistribution = {
  __typename: "CountryDistribution",
  country?: string | null,
  loanAmount?: number | null,
};

export type CompanyDistribution = {
  __typename: "CompanyDistribution",
  company?: string | null,
  companyId?: string | null,
  loanAmount?: number | null,
};

export type LoanDistribution = {
  __typename: "LoanDistribution",
  loanName?: string | null,
  loanId?: string | null,
  renewalId?: string | null,
  applicationId?: string | null,
  loanType?: string | null,
  loanAmount?: number | null,
};

export type TradeMetrics = {
  __typename: "TradeMetrics",
  numberOfSuppliers?: number | null,
  numberOfBuyers?: number | null,
  brokerageRevenue?: number | null,
  numberOfTransactions?: number | null,
  numberOfBuyersServed?: number | null,
  grossMerchandisedValue?: number | null,
};

export type MCAMetrics = {
  __typename: "MCAMetrics",
  totalMCAOpened?: number | null,
  totalMCAInflowVolume?: number | null,
  totalMCAOutflowVolume?: number | null,
  MCAInflowVolume?: number | null,
  MCAOutflowVolume?: number | null,
  newFxVolume?: number | null,
  USDMCAAcounts?: number | null,
  EURMCAAcounts?: number | null,
  GBPMCAAcounts?: number | null,
};

export type UpdateKPIMetricsInput = {
  id: string,
  day?: string | null,
  investmentData?: InvestmentMetricsInput | null,
  complianceData?: ComplianceMetricsInput | null,
  lendingData?: LendingMetricsInput | null,
  tradeData?: TradeMetricsInput | null,
  MCAData?: MCAMetricsInput | null,
  USDRateUsed?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteKPIMetricsInput = {
  id: string,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  description?: string | null,
  amount?: number | null,
  currency?: string | null,
  channel?: string | null,
  type?: string | null,
  date?: string | null,
  dateCreated?: string | null,
  accountId?: string | null,
};

export type ModelUsersFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUsersFilterInput | null > | null,
  or?: Array< ModelUsersFilterInput | null > | null,
  not?: ModelUsersFilterInput | null,
};

export type ModelUsersConnection = {
  __typename: "ModelUsersConnection",
  items:  Array<Users | null >,
  nextToken?: string | null,
};

export type ModelTextractRecordsFilterInput = {
  id?: ModelIDInput | null,
  bankName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  country?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  processingStatus?: ModelStringInput | null,
  transactionFileName?: ModelStringInput | null,
  uploaderImage?: ModelStringInput | null,
  uploaderName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractRecordsFilterInput | null > | null,
  or?: Array< ModelTextractRecordsFilterInput | null > | null,
  not?: ModelTextractRecordsFilterInput | null,
};

export type ModelTextractRecordsConnection = {
  __typename: "ModelTextractRecordsConnection",
  items:  Array<TextractRecords | null >,
  nextToken?: string | null,
};

export type ModelTextractEntitiesFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  count?: ModelIntInput | null,
  inflows?: ModelFloatInput | null,
  outflows?: ModelFloatInput | null,
  average?: ModelFloatInput | null,
  country?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTextractEntitiesFilterInput | null > | null,
  or?: Array< ModelTextractEntitiesFilterInput | null > | null,
  not?: ModelTextractEntitiesFilterInput | null,
};

export type ModelTextractEntitiesConnection = {
  __typename: "ModelTextractEntitiesConnection",
  items:  Array<TextractEntities | null >,
  nextToken?: string | null,
};

export type ModelWaiverRequestsFilterInput = {
  id?: ModelIDInput | null,
  loanId?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  paymentId?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  lateFee?: ModelFloatInput | null,
  requester?: ModelStringInput | null,
  requestedBy?: ModelStringInput | null,
  requesterImage?: ModelStringInput | null,
  requestNotes?: ModelStringInput | null,
  waiverRequested?: ModelFloatInput | null,
  approver?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  approvedBy?: ModelStringInput | null,
  approverImage?: ModelStringInput | null,
  approvalNotes?: ModelStringInput | null,
  waiverApproved?: ModelFloatInput | null,
  status?: ModelWaiverStatusInput | null,
  supportingDocuments?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWaiverRequestsFilterInput | null > | null,
  or?: Array< ModelWaiverRequestsFilterInput | null > | null,
  not?: ModelWaiverRequestsFilterInput | null,
};

export type ModelWaiverRequestsConnection = {
  __typename: "ModelWaiverRequestsConnection",
  items:  Array<WaiverRequests | null >,
  nextToken?: string | null,
};

export type ModelDocumentsFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  key?: ModelStringInput | null,
  bucketName?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  category?: ModelDocumentCategoryInput | null,
  complianceStatus?: ModelCompanyKYCStatusInput | null,
  expiresAt?: ModelStringInput | null,
  entityType?: ModelEntityTypesInput | null,
  entityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentsFilterInput | null > | null,
  or?: Array< ModelDocumentsFilterInput | null > | null,
  not?: ModelDocumentsFilterInput | null,
};

export type ModelDocumentsConnection = {
  __typename: "ModelDocumentsConnection",
  items:  Array<Documents | null >,
  nextToken?: string | null,
};

export type ModelKPIMetricsFilterInput = {
  id?: ModelIDInput | null,
  day?: ModelStringInput | null,
  USDRateUsed?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelKPIMetricsFilterInput | null > | null,
  or?: Array< ModelKPIMetricsFilterInput | null > | null,
  not?: ModelKPIMetricsFilterInput | null,
};

export type ModelKPIMetricsConnection = {
  __typename: "ModelKPIMetricsConnection",
  items:  Array<KPIMetrics | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionUsersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUsersFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsersFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionTextractRecordsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  processingStatus?: ModelSubscriptionStringInput | null,
  transactionFileName?: ModelSubscriptionStringInput | null,
  uploaderImage?: ModelSubscriptionStringInput | null,
  uploaderName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTextractRecordsFilterInput | null > | null,
  or?: Array< ModelSubscriptionTextractRecordsFilterInput | null > | null,
};

export type ModelSubscriptionTextractEntitiesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionIntInput | null,
  inflows?: ModelSubscriptionFloatInput | null,
  outflows?: ModelSubscriptionFloatInput | null,
  average?: ModelSubscriptionFloatInput | null,
  country?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTextractEntitiesFilterInput | null > | null,
  or?: Array< ModelSubscriptionTextractEntitiesFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTextractTransactionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  bank?: ModelSubscriptionStringInput | null,
  isCredit?: ModelSubscriptionBooleanInput | null,
  currency?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  modifiedDate?: ModelSubscriptionStringInput | null,
  formattedDate?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  entityId?: ModelSubscriptionStringInput | null,
  entityName?: ModelSubscriptionStringInput | null,
  entityType?: ModelSubscriptionStringInput | null,
  fileId?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  processingBank?: ModelSubscriptionStringInput | null,
  additionalText?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  chequeNo?: ModelSubscriptionStringInput | null,
  customerReference?: ModelSubscriptionStringInput | null,
  instrumentCode?: ModelSubscriptionStringInput | null,
  reference?: ModelSubscriptionStringInput | null,
  transactionType?: ModelSubscriptionStringInput | null,
  valueDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTextractTransactionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionTextractTransactionsFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFlunaMembersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  primaryTeam?: ModelSubscriptionStringInput | null,
  lastActive?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  googleUserCognitoId?: ModelSubscriptionStringInput | null,
  emailUserCognitoId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFlunaMembersFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlunaMembersFilterInput | null > | null,
};

export type ModelSubscriptionWaiverRequestsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  loanId?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionStringInput | null,
  paymentId?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  lateFee?: ModelSubscriptionFloatInput | null,
  requester?: ModelSubscriptionStringInput | null,
  requestedBy?: ModelSubscriptionStringInput | null,
  requesterImage?: ModelSubscriptionStringInput | null,
  requestNotes?: ModelSubscriptionStringInput | null,
  waiverRequested?: ModelSubscriptionFloatInput | null,
  approver?: ModelSubscriptionStringInput | null,
  approvedAt?: ModelSubscriptionStringInput | null,
  approvedBy?: ModelSubscriptionStringInput | null,
  approverImage?: ModelSubscriptionStringInput | null,
  approvalNotes?: ModelSubscriptionStringInput | null,
  waiverApproved?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  supportingDocuments?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWaiverRequestsFilterInput | null > | null,
  or?: Array< ModelSubscriptionWaiverRequestsFilterInput | null > | null,
};

export type ModelSubscriptionDocumentsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  key?: ModelSubscriptionStringInput | null,
  bucketName?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  complianceStatus?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  entityType?: ModelSubscriptionStringInput | null,
  entityId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentsFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentsFilterInput | null > | null,
};

export type ModelSubscriptionKPIMetricsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  day?: ModelSubscriptionStringInput | null,
  USDRateUsed?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionKPIMetricsFilterInput | null > | null,
  or?: Array< ModelSubscriptionKPIMetricsFilterInput | null > | null,
};

export type customTransactionsByRecordIdQueryVariables = {
  fileId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextractTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type customTransactionsByRecordIdQuery = {
  transactionsByRecordId?:  {
    __typename: "ModelTextractTransactionsConnection",
    items:  Array< {
      __typename: "TextractTransactions",
      id: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customUserByEmailQueryVariables = {
  email: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlunaMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type customUserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelFlunaMembersConnection",
    items:  Array< {
      __typename: "FlunaMembers",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      primaryTeam?: FlunaTeams | null,
      lastActive?: string | null,
      picture?: string | null,
      modules?:  Array< {
        __typename: "MemberModules",
        name?: FlunaModules | null,
        description?: string | null,
        access?: AccessLevels | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customListFlunaMembersQueryVariables = {
  filter?: ModelFlunaMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type customListFlunaMembersQuery = {
  listFlunaMembers?:  {
    __typename: "ModelFlunaMembersConnection",
    items:  Array< {
      __typename: "FlunaMembers",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      primaryTeam?: FlunaTeams | null,
      lastActive?: string | null,
      picture?: string | null,
      modules?:  Array< {
        __typename: "MemberModules",
        name?: FlunaModules | null,
        description?: string | null,
        access?: AccessLevels | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateUsersMutationVariables = {
  input: CreateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type CreateUsersMutation = {
  createUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateUsersMutationVariables = {
  input: UpdateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type UpdateUsersMutation = {
  updateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteUsersMutationVariables = {
  input: DeleteUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type DeleteUsersMutation = {
  deleteUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateTextractRecordsMutationVariables = {
  input: CreateTextractRecordsInput,
  condition?: ModelTextractRecordsConditionInput | null,
};

export type CreateTextractRecordsMutation = {
  createTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateTextractRecordsMutationVariables = {
  input: UpdateTextractRecordsInput,
  condition?: ModelTextractRecordsConditionInput | null,
};

export type UpdateTextractRecordsMutation = {
  updateTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteTextractRecordsMutationVariables = {
  input: DeleteTextractRecordsInput,
  condition?: ModelTextractRecordsConditionInput | null,
};

export type DeleteTextractRecordsMutation = {
  deleteTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateTextractEntitiesMutationVariables = {
  input: CreateTextractEntitiesInput,
  condition?: ModelTextractEntitiesConditionInput | null,
};

export type CreateTextractEntitiesMutation = {
  createTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateTextractEntitiesMutationVariables = {
  input: UpdateTextractEntitiesInput,
  condition?: ModelTextractEntitiesConditionInput | null,
};

export type UpdateTextractEntitiesMutation = {
  updateTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteTextractEntitiesMutationVariables = {
  input: DeleteTextractEntitiesInput,
  condition?: ModelTextractEntitiesConditionInput | null,
};

export type DeleteTextractEntitiesMutation = {
  deleteTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateTextractTransactionsMutationVariables = {
  input: CreateTextractTransactionsInput,
  condition?: ModelTextractTransactionsConditionInput | null,
};

export type CreateTextractTransactionsMutation = {
  createTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateTextractTransactionsMutationVariables = {
  input: UpdateTextractTransactionsInput,
  condition?: ModelTextractTransactionsConditionInput | null,
};

export type UpdateTextractTransactionsMutation = {
  updateTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteTextractTransactionsMutationVariables = {
  input: DeleteTextractTransactionsInput,
  condition?: ModelTextractTransactionsConditionInput | null,
};

export type DeleteTextractTransactionsMutation = {
  deleteTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateFlunaMembersMutationVariables = {
  input: CreateFlunaMembersInput,
  condition?: ModelFlunaMembersConditionInput | null,
};

export type CreateFlunaMembersMutation = {
  createFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateFlunaMembersMutationVariables = {
  input: UpdateFlunaMembersInput,
  condition?: ModelFlunaMembersConditionInput | null,
};

export type UpdateFlunaMembersMutation = {
  updateFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteFlunaMembersMutationVariables = {
  input: DeleteFlunaMembersInput,
  condition?: ModelFlunaMembersConditionInput | null,
};

export type DeleteFlunaMembersMutation = {
  deleteFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateWaiverRequestsMutationVariables = {
  input: CreateWaiverRequestsInput,
  condition?: ModelWaiverRequestsConditionInput | null,
};

export type CreateWaiverRequestsMutation = {
  createWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateWaiverRequestsMutationVariables = {
  input: UpdateWaiverRequestsInput,
  condition?: ModelWaiverRequestsConditionInput | null,
};

export type UpdateWaiverRequestsMutation = {
  updateWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteWaiverRequestsMutationVariables = {
  input: DeleteWaiverRequestsInput,
  condition?: ModelWaiverRequestsConditionInput | null,
};

export type DeleteWaiverRequestsMutation = {
  deleteWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateDocumentsMutationVariables = {
  input: CreateDocumentsInput,
  condition?: ModelDocumentsConditionInput | null,
};

export type CreateDocumentsMutation = {
  createDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateDocumentsMutationVariables = {
  input: UpdateDocumentsInput,
  condition?: ModelDocumentsConditionInput | null,
};

export type UpdateDocumentsMutation = {
  updateDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteDocumentsMutationVariables = {
  input: DeleteDocumentsInput,
  condition?: ModelDocumentsConditionInput | null,
};

export type DeleteDocumentsMutation = {
  deleteDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateKPIMetricsMutationVariables = {
  input: CreateKPIMetricsInput,
  condition?: ModelKPIMetricsConditionInput | null,
};

export type CreateKPIMetricsMutation = {
  createKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateKPIMetricsMutationVariables = {
  input: UpdateKPIMetricsInput,
  condition?: ModelKPIMetricsConditionInput | null,
};

export type UpdateKPIMetricsMutation = {
  updateKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteKPIMetricsMutationVariables = {
  input: DeleteKPIMetricsInput,
  condition?: ModelKPIMetricsConditionInput | null,
};

export type DeleteKPIMetricsMutation = {
  deleteKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type FetchAccountTransactionsQueryVariables = {
  accountId: string,
};

export type FetchAccountTransactionsQuery = {
  fetchAccountTransactions?:  Array< {
    __typename: "Transaction",
    id: string,
    description?: string | null,
    amount?: number | null,
    currency?: string | null,
    channel?: string | null,
    type?: string | null,
    date?: string | null,
    dateCreated?: string | null,
    accountId?: string | null,
  } | null > | null,
};

export type CustomGenericMailSenderQueryVariables = {
  recipientMails?: Array< string | null > | null,
  mailSubject?: string | null,
  mailBody?: string | null,
  mailSender?: string | null,
  ccMails?: Array< string | null > | null,
  bccMails?: Array< string | null > | null,
  replyAddresses?: Array< string | null > | null,
};

export type CustomGenericMailSenderQuery = {
  customGenericMailSender?: string | null,
};

export type GetUsersQueryVariables = {
  id: string,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUsersConnection",
    items:  Array< {
      __typename: "Users",
      id: string,
      name?: string | null,
      email: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTextractRecordsQueryVariables = {
  id: string,
};

export type GetTextractRecordsQuery = {
  getTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListTextractRecordsQueryVariables = {
  filter?: ModelTextractRecordsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTextractRecordsQuery = {
  listTextractRecords?:  {
    __typename: "ModelTextractRecordsConnection",
    items:  Array< {
      __typename: "TextractRecords",
      id: string,
      bankName?: string | null,
      companyName?: string | null,
      country?: string | null,
      currency?: string | null,
      fileName?: string | null,
      processingStatus?: string | null,
      transactionFileName?: string | null,
      uploaderImage?: string | null,
      uploaderName?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTextractEntitiesQueryVariables = {
  id: string,
};

export type GetTextractEntitiesQuery = {
  getTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListTextractEntitiesQueryVariables = {
  filter?: ModelTextractEntitiesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTextractEntitiesQuery = {
  listTextractEntities?:  {
    __typename: "ModelTextractEntitiesConnection",
    items:  Array< {
      __typename: "TextractEntities",
      id: string,
      name?: string | null,
      type?: string | null,
      count?: number | null,
      inflows?: number | null,
      outflows?: number | null,
      average?: number | null,
      country?: string | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTextractTransactionsQueryVariables = {
  id: string,
};

export type GetTextractTransactionsQuery = {
  getTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListTextractTransactionsQueryVariables = {
  filter?: ModelTextractTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTextractTransactionsQuery = {
  listTextractTransactions?:  {
    __typename: "ModelTextractTransactionsConnection",
    items:  Array< {
      __typename: "TextractTransactions",
      id: string,
      amount?: number | null,
      bank?: string | null,
      isCredit?: boolean | null,
      currency?: string | null,
      date?: string | null,
      modifiedDate?: string | null,
      formattedDate?: string | null,
      description?: string | null,
      entityId?: string | null,
      entityName?: string | null,
      entityType?: string | null,
      fileId?: string | null,
      fileName?: string | null,
      companyName?: string | null,
      processingBank?: string | null,
      additionalText?: string | null,
      channel?: string | null,
      chequeNo?: string | null,
      customerReference?: string | null,
      instrumentCode?: string | null,
      reference?: string | null,
      transactionType?: string | null,
      valueDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFlunaMembersQueryVariables = {
  id: string,
};

export type GetFlunaMembersQuery = {
  getFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListFlunaMembersQueryVariables = {
  filter?: ModelFlunaMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlunaMembersQuery = {
  listFlunaMembers?:  {
    __typename: "ModelFlunaMembersConnection",
    items:  Array< {
      __typename: "FlunaMembers",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      primaryTeam?: FlunaTeams | null,
      lastActive?: string | null,
      picture?: string | null,
      modules?:  Array< {
        __typename: "MemberModules",
        name?: FlunaModules | null,
        description?: string | null,
        access?: AccessLevels | null,
      } | null > | null,
      status?: MemberStatus | null,
      googleUserCognitoId?: string | null,
      emailUserCognitoId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWaiverRequestsQueryVariables = {
  id: string,
};

export type GetWaiverRequestsQuery = {
  getWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListWaiverRequestsQueryVariables = {
  filter?: ModelWaiverRequestsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWaiverRequestsQuery = {
  listWaiverRequests?:  {
    __typename: "ModelWaiverRequestsConnection",
    items:  Array< {
      __typename: "WaiverRequests",
      id: string,
      loanId?: string | null,
      companyId?: string | null,
      paymentId?: string | null,
      currency?: string | null,
      lateFee?: number | null,
      requester?: string | null,
      requestedBy?: string | null,
      requesterImage?: string | null,
      requestNotes?: string | null,
      waiverRequested?: number | null,
      approver?: Array< string | null > | null,
      approvedAt?: string | null,
      approvedBy?: string | null,
      approverImage?: string | null,
      approvalNotes?: string | null,
      waiverApproved?: number | null,
      status?: WaiverStatus | null,
      supportingDocuments?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentsQueryVariables = {
  id: string,
};

export type GetDocumentsQuery = {
  getDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentsConnection",
    items:  Array< {
      __typename: "Documents",
      id: string,
      companyId?: string | null,
      key?: string | null,
      bucketName?: string | null,
      fileName?: string | null,
      name?: string | null,
      category?: DocumentCategory | null,
      complianceStatus?: CompanyKYCStatus | null,
      expiresAt?: string | null,
      entityType?: EntityTypes | null,
      entityId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetKPIMetricsQueryVariables = {
  id: string,
};

export type GetKPIMetricsQuery = {
  getKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListKPIMetricsQueryVariables = {
  filter?: ModelKPIMetricsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKPIMetricsQuery = {
  listKPIMetrics?:  {
    __typename: "ModelKPIMetricsConnection",
    items:  Array< {
      __typename: "KPIMetrics",
      id: string,
      day: string,
      investmentData?:  {
        __typename: "InvestmentMetrics",
        totalInvestments?: number | null,
        totalActiveInvestments?: number | null,
        totalWalletBalance?: number | null,
        debtInvestmentAdded?: number | null,
        debtInvestmentWithdrawn?: number | null,
        totalInvestors?: number | null,
        activeInvestors?: number | null,
        investmentSize?: number | null,
        averageInvestmentSize?: number | null,
        weightedAverageCostOfCapital?: number | null,
      } | null,
      complianceData?:  {
        __typename: "ComplianceMetrics",
        numberOfUsersOnPlatform?: number | null,
        numberOfCompaniesOnPlatform?: number | null,
        numberOfOnboardedCompany?: number | null,
        numberOfKycApprovedCompany?: number | null,
        numberOfCompaniesWithBankAccountsConnected?: number | null,
      } | null,
      lendingData?:  {
        __typename: "LendingMetrics",
        totalActiveUSDLoansCount?: number | null,
        totalActiveNGNLoansCount?: number | null,
        totalActiveUSDLoansAmount?: number | null,
        totalActiveNGNLoansAmount?: number | null,
        totalActiveLoansAmount?: number | null,
        totalActiveNonNigerianLoansCount?: number | null,
        totalActiveNigerianLoansCount?: number | null,
        totalActiveNonNigerianLoansAmount?: number | null,
        totalActiveNigerianLoansAmount?: number | null,
        totalCummulativeLoansAmount?: number | null,
        monthlyInterestIncomeAllLoans?: number | null,
        monthlyInterestIncomeLt90Loans?: number | null,
        totalOngoingLoansAmount?: number | null,
        totalLateLoansAmount?: number | null,
        totalPaidLoansAmount?: number | null,
        loansDistributionByCountry?:  Array< {
          __typename: "CountryDistribution",
          country?: string | null,
          loanAmount?: number | null,
        } | null > | null,
        loansDistributionByCompany?:  Array< {
          __typename: "CompanyDistribution",
          company?: string | null,
          companyId?: string | null,
          loanAmount?: number | null,
        } | null > | null,
        loansDistributionByLoan?:  Array< {
          __typename: "LoanDistribution",
          loanName?: string | null,
          loanId?: string | null,
          renewalId?: string | null,
          applicationId?: string | null,
          loanType?: string | null,
          loanAmount?: number | null,
        } | null > | null,
      } | null,
      tradeData?:  {
        __typename: "TradeMetrics",
        numberOfSuppliers?: number | null,
        numberOfBuyers?: number | null,
        brokerageRevenue?: number | null,
        numberOfTransactions?: number | null,
        numberOfBuyersServed?: number | null,
        grossMerchandisedValue?: number | null,
      } | null,
      MCAData?:  {
        __typename: "MCAMetrics",
        totalMCAOpened?: number | null,
        totalMCAInflowVolume?: number | null,
        totalMCAOutflowVolume?: number | null,
        MCAInflowVolume?: number | null,
        MCAOutflowVolume?: number | null,
        newFxVolume?: number | null,
        USDMCAAcounts?: number | null,
        EURMCAAcounts?: number | null,
        GBPMCAAcounts?: number | null,
      } | null,
      USDRateUsed?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EntitiesByCurrencyQueryVariables = {
  currency: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextractEntitiesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EntitiesByCurrencyQuery = {
  entitiesByCurrency?:  {
    __typename: "ModelTextractEntitiesConnection",
    items:  Array< {
      __typename: "TextractEntities",
      id: string,
      name?: string | null,
      type?: string | null,
      count?: number | null,
      inflows?: number | null,
      outflows?: number | null,
      average?: number | null,
      country?: string | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByEntityIdQueryVariables = {
  entityId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextractTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByEntityIdQuery = {
  transactionsByEntityId?:  {
    __typename: "ModelTextractTransactionsConnection",
    items:  Array< {
      __typename: "TextractTransactions",
      id: string,
      amount?: number | null,
      bank?: string | null,
      isCredit?: boolean | null,
      currency?: string | null,
      date?: string | null,
      modifiedDate?: string | null,
      formattedDate?: string | null,
      description?: string | null,
      entityId?: string | null,
      entityName?: string | null,
      entityType?: string | null,
      fileId?: string | null,
      fileName?: string | null,
      companyName?: string | null,
      processingBank?: string | null,
      additionalText?: string | null,
      channel?: string | null,
      chequeNo?: string | null,
      customerReference?: string | null,
      instrumentCode?: string | null,
      reference?: string | null,
      transactionType?: string | null,
      valueDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByRecordIdQueryVariables = {
  fileId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextractTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByRecordIdQuery = {
  transactionsByRecordId?:  {
    __typename: "ModelTextractTransactionsConnection",
    items:  Array< {
      __typename: "TextractTransactions",
      id: string,
      amount?: number | null,
      bank?: string | null,
      isCredit?: boolean | null,
      currency?: string | null,
      date?: string | null,
      modifiedDate?: string | null,
      formattedDate?: string | null,
      description?: string | null,
      entityId?: string | null,
      entityName?: string | null,
      entityType?: string | null,
      fileId?: string | null,
      fileName?: string | null,
      companyName?: string | null,
      processingBank?: string | null,
      additionalText?: string | null,
      channel?: string | null,
      chequeNo?: string | null,
      customerReference?: string | null,
      instrumentCode?: string | null,
      reference?: string | null,
      transactionType?: string | null,
      valueDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlunaMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelFlunaMembersConnection",
    items:  Array< {
      __typename: "FlunaMembers",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      primaryTeam?: FlunaTeams | null,
      lastActive?: string | null,
      picture?: string | null,
      modules?:  Array< {
        __typename: "MemberModules",
        name?: FlunaModules | null,
        description?: string | null,
        access?: AccessLevels | null,
      } | null > | null,
      status?: MemberStatus | null,
      googleUserCognitoId?: string | null,
      emailUserCognitoId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WaiverRequestsByLoanQueryVariables = {
  loanId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWaiverRequestsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WaiverRequestsByLoanQuery = {
  waiverRequestsByLoan?:  {
    __typename: "ModelWaiverRequestsConnection",
    items:  Array< {
      __typename: "WaiverRequests",
      id: string,
      loanId?: string | null,
      companyId?: string | null,
      paymentId?: string | null,
      currency?: string | null,
      lateFee?: number | null,
      requester?: string | null,
      requestedBy?: string | null,
      requesterImage?: string | null,
      requestNotes?: string | null,
      waiverRequested?: number | null,
      approver?: Array< string | null > | null,
      approvedAt?: string | null,
      approvedBy?: string | null,
      approverImage?: string | null,
      approvalNotes?: string | null,
      waiverApproved?: number | null,
      status?: WaiverStatus | null,
      supportingDocuments?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DocumentsByEntityIdQueryVariables = {
  entityId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByEntityIdQuery = {
  documentsByEntityId?:  {
    __typename: "ModelDocumentsConnection",
    items:  Array< {
      __typename: "Documents",
      id: string,
      companyId?: string | null,
      key?: string | null,
      bucketName?: string | null,
      fileName?: string | null,
      name?: string | null,
      category?: DocumentCategory | null,
      complianceStatus?: CompanyKYCStatus | null,
      expiresAt?: string | null,
      entityType?: EntityTypes | null,
      entityId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MetricsByDayQueryVariables = {
  day: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelKPIMetricsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MetricsByDayQuery = {
  metricsByDay?:  {
    __typename: "ModelKPIMetricsConnection",
    items:  Array< {
      __typename: "KPIMetrics",
      id: string,
      day: string,
      investmentData?:  {
        __typename: "InvestmentMetrics",
        totalInvestments?: number | null,
        totalActiveInvestments?: number | null,
        totalWalletBalance?: number | null,
        debtInvestmentAdded?: number | null,
        debtInvestmentWithdrawn?: number | null,
        totalInvestors?: number | null,
        activeInvestors?: number | null,
        investmentSize?: number | null,
        averageInvestmentSize?: number | null,
        weightedAverageCostOfCapital?: number | null,
      } | null,
      complianceData?:  {
        __typename: "ComplianceMetrics",
        numberOfUsersOnPlatform?: number | null,
        numberOfCompaniesOnPlatform?: number | null,
        numberOfOnboardedCompany?: number | null,
        numberOfKycApprovedCompany?: number | null,
        numberOfCompaniesWithBankAccountsConnected?: number | null,
      } | null,
      lendingData?:  {
        __typename: "LendingMetrics",
        totalActiveUSDLoansCount?: number | null,
        totalActiveNGNLoansCount?: number | null,
        totalActiveUSDLoansAmount?: number | null,
        totalActiveNGNLoansAmount?: number | null,
        totalActiveLoansAmount?: number | null,
        totalActiveNonNigerianLoansCount?: number | null,
        totalActiveNigerianLoansCount?: number | null,
        totalActiveNonNigerianLoansAmount?: number | null,
        totalActiveNigerianLoansAmount?: number | null,
        totalCummulativeLoansAmount?: number | null,
        monthlyInterestIncomeAllLoans?: number | null,
        monthlyInterestIncomeLt90Loans?: number | null,
        totalOngoingLoansAmount?: number | null,
        totalLateLoansAmount?: number | null,
        totalPaidLoansAmount?: number | null,
        loansDistributionByCountry?:  Array< {
          __typename: "CountryDistribution",
          country?: string | null,
          loanAmount?: number | null,
        } | null > | null,
        loansDistributionByCompany?:  Array< {
          __typename: "CompanyDistribution",
          company?: string | null,
          companyId?: string | null,
          loanAmount?: number | null,
        } | null > | null,
        loansDistributionByLoan?:  Array< {
          __typename: "LoanDistribution",
          loanName?: string | null,
          loanId?: string | null,
          renewalId?: string | null,
          applicationId?: string | null,
          loanType?: string | null,
          loanAmount?: number | null,
        } | null > | null,
      } | null,
      tradeData?:  {
        __typename: "TradeMetrics",
        numberOfSuppliers?: number | null,
        numberOfBuyers?: number | null,
        brokerageRevenue?: number | null,
        numberOfTransactions?: number | null,
        numberOfBuyersServed?: number | null,
        grossMerchandisedValue?: number | null,
      } | null,
      MCAData?:  {
        __typename: "MCAMetrics",
        totalMCAOpened?: number | null,
        totalMCAInflowVolume?: number | null,
        totalMCAOutflowVolume?: number | null,
        MCAInflowVolume?: number | null,
        MCAOutflowVolume?: number | null,
        newFxVolume?: number | null,
        USDMCAAcounts?: number | null,
        EURMCAAcounts?: number | null,
        GBPMCAAcounts?: number | null,
      } | null,
      USDRateUsed?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnCreateUsersSubscription = {
  onCreateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnUpdateUsersSubscription = {
  onUpdateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
};

export type OnDeleteUsersSubscription = {
  onDeleteUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateTextractRecordsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractRecordsFilterInput | null,
};

export type OnCreateTextractRecordsSubscription = {
  onCreateTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateTextractRecordsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractRecordsFilterInput | null,
};

export type OnUpdateTextractRecordsSubscription = {
  onUpdateTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteTextractRecordsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractRecordsFilterInput | null,
};

export type OnDeleteTextractRecordsSubscription = {
  onDeleteTextractRecords?:  {
    __typename: "TextractRecords",
    id: string,
    bankName?: string | null,
    companyName?: string | null,
    country?: string | null,
    currency?: string | null,
    fileName?: string | null,
    processingStatus?: string | null,
    transactionFileName?: string | null,
    uploaderImage?: string | null,
    uploaderName?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateTextractEntitiesSubscriptionVariables = {
  filter?: ModelSubscriptionTextractEntitiesFilterInput | null,
};

export type OnCreateTextractEntitiesSubscription = {
  onCreateTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateTextractEntitiesSubscriptionVariables = {
  filter?: ModelSubscriptionTextractEntitiesFilterInput | null,
};

export type OnUpdateTextractEntitiesSubscription = {
  onUpdateTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteTextractEntitiesSubscriptionVariables = {
  filter?: ModelSubscriptionTextractEntitiesFilterInput | null,
};

export type OnDeleteTextractEntitiesSubscription = {
  onDeleteTextractEntities?:  {
    __typename: "TextractEntities",
    id: string,
    name?: string | null,
    type?: string | null,
    count?: number | null,
    inflows?: number | null,
    outflows?: number | null,
    average?: number | null,
    country?: string | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateTextractTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractTransactionsFilterInput | null,
};

export type OnCreateTextractTransactionsSubscription = {
  onCreateTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateTextractTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractTransactionsFilterInput | null,
};

export type OnUpdateTextractTransactionsSubscription = {
  onUpdateTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteTextractTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionTextractTransactionsFilterInput | null,
};

export type OnDeleteTextractTransactionsSubscription = {
  onDeleteTextractTransactions?:  {
    __typename: "TextractTransactions",
    id: string,
    amount?: number | null,
    bank?: string | null,
    isCredit?: boolean | null,
    currency?: string | null,
    date?: string | null,
    modifiedDate?: string | null,
    formattedDate?: string | null,
    description?: string | null,
    entityId?: string | null,
    entityName?: string | null,
    entityType?: string | null,
    fileId?: string | null,
    fileName?: string | null,
    companyName?: string | null,
    processingBank?: string | null,
    additionalText?: string | null,
    channel?: string | null,
    chequeNo?: string | null,
    customerReference?: string | null,
    instrumentCode?: string | null,
    reference?: string | null,
    transactionType?: string | null,
    valueDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateFlunaMembersSubscriptionVariables = {
  filter?: ModelSubscriptionFlunaMembersFilterInput | null,
};

export type OnCreateFlunaMembersSubscription = {
  onCreateFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateFlunaMembersSubscriptionVariables = {
  filter?: ModelSubscriptionFlunaMembersFilterInput | null,
};

export type OnUpdateFlunaMembersSubscription = {
  onUpdateFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteFlunaMembersSubscriptionVariables = {
  filter?: ModelSubscriptionFlunaMembersFilterInput | null,
};

export type OnDeleteFlunaMembersSubscription = {
  onDeleteFlunaMembers?:  {
    __typename: "FlunaMembers",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    primaryTeam?: FlunaTeams | null,
    lastActive?: string | null,
    picture?: string | null,
    modules?:  Array< {
      __typename: "MemberModules",
      name?: FlunaModules | null,
      description?: string | null,
      access?: AccessLevels | null,
    } | null > | null,
    status?: MemberStatus | null,
    googleUserCognitoId?: string | null,
    emailUserCognitoId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateWaiverRequestsSubscriptionVariables = {
  filter?: ModelSubscriptionWaiverRequestsFilterInput | null,
};

export type OnCreateWaiverRequestsSubscription = {
  onCreateWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateWaiverRequestsSubscriptionVariables = {
  filter?: ModelSubscriptionWaiverRequestsFilterInput | null,
};

export type OnUpdateWaiverRequestsSubscription = {
  onUpdateWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteWaiverRequestsSubscriptionVariables = {
  filter?: ModelSubscriptionWaiverRequestsFilterInput | null,
};

export type OnDeleteWaiverRequestsSubscription = {
  onDeleteWaiverRequests?:  {
    __typename: "WaiverRequests",
    id: string,
    loanId?: string | null,
    companyId?: string | null,
    paymentId?: string | null,
    currency?: string | null,
    lateFee?: number | null,
    requester?: string | null,
    requestedBy?: string | null,
    requesterImage?: string | null,
    requestNotes?: string | null,
    waiverRequested?: number | null,
    approver?: Array< string | null > | null,
    approvedAt?: string | null,
    approvedBy?: string | null,
    approverImage?: string | null,
    approvalNotes?: string | null,
    waiverApproved?: number | null,
    status?: WaiverStatus | null,
    supportingDocuments?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null,
};

export type OnCreateDocumentsSubscription = {
  onCreateDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null,
};

export type OnUpdateDocumentsSubscription = {
  onUpdateDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null,
};

export type OnDeleteDocumentsSubscription = {
  onDeleteDocuments?:  {
    __typename: "Documents",
    id: string,
    companyId?: string | null,
    key?: string | null,
    bucketName?: string | null,
    fileName?: string | null,
    name?: string | null,
    category?: DocumentCategory | null,
    complianceStatus?: CompanyKYCStatus | null,
    expiresAt?: string | null,
    entityType?: EntityTypes | null,
    entityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateKPIMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionKPIMetricsFilterInput | null,
};

export type OnCreateKPIMetricsSubscription = {
  onCreateKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateKPIMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionKPIMetricsFilterInput | null,
};

export type OnUpdateKPIMetricsSubscription = {
  onUpdateKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteKPIMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionKPIMetricsFilterInput | null,
};

export type OnDeleteKPIMetricsSubscription = {
  onDeleteKPIMetrics?:  {
    __typename: "KPIMetrics",
    id: string,
    day: string,
    investmentData?:  {
      __typename: "InvestmentMetrics",
      totalInvestments?: number | null,
      totalActiveInvestments?: number | null,
      totalWalletBalance?: number | null,
      debtInvestmentAdded?: number | null,
      debtInvestmentWithdrawn?: number | null,
      totalInvestors?: number | null,
      activeInvestors?: number | null,
      investmentSize?: number | null,
      averageInvestmentSize?: number | null,
      weightedAverageCostOfCapital?: number | null,
    } | null,
    complianceData?:  {
      __typename: "ComplianceMetrics",
      numberOfUsersOnPlatform?: number | null,
      numberOfCompaniesOnPlatform?: number | null,
      numberOfOnboardedCompany?: number | null,
      numberOfKycApprovedCompany?: number | null,
      numberOfCompaniesWithBankAccountsConnected?: number | null,
    } | null,
    lendingData?:  {
      __typename: "LendingMetrics",
      totalActiveUSDLoansCount?: number | null,
      totalActiveNGNLoansCount?: number | null,
      totalActiveUSDLoansAmount?: number | null,
      totalActiveNGNLoansAmount?: number | null,
      totalActiveLoansAmount?: number | null,
      totalActiveNonNigerianLoansCount?: number | null,
      totalActiveNigerianLoansCount?: number | null,
      totalActiveNonNigerianLoansAmount?: number | null,
      totalActiveNigerianLoansAmount?: number | null,
      totalCummulativeLoansAmount?: number | null,
      monthlyInterestIncomeAllLoans?: number | null,
      monthlyInterestIncomeLt90Loans?: number | null,
      totalOngoingLoansAmount?: number | null,
      totalLateLoansAmount?: number | null,
      totalPaidLoansAmount?: number | null,
      loansDistributionByCountry?:  Array< {
        __typename: "CountryDistribution",
        country?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByCompany?:  Array< {
        __typename: "CompanyDistribution",
        company?: string | null,
        companyId?: string | null,
        loanAmount?: number | null,
      } | null > | null,
      loansDistributionByLoan?:  Array< {
        __typename: "LoanDistribution",
        loanName?: string | null,
        loanId?: string | null,
        renewalId?: string | null,
        applicationId?: string | null,
        loanType?: string | null,
        loanAmount?: number | null,
      } | null > | null,
    } | null,
    tradeData?:  {
      __typename: "TradeMetrics",
      numberOfSuppliers?: number | null,
      numberOfBuyers?: number | null,
      brokerageRevenue?: number | null,
      numberOfTransactions?: number | null,
      numberOfBuyersServed?: number | null,
      grossMerchandisedValue?: number | null,
    } | null,
    MCAData?:  {
      __typename: "MCAMetrics",
      totalMCAOpened?: number | null,
      totalMCAInflowVolume?: number | null,
      totalMCAOutflowVolume?: number | null,
      MCAInflowVolume?: number | null,
      MCAOutflowVolume?: number | null,
      newFxVolume?: number | null,
      USDMCAAcounts?: number | null,
      EURMCAAcounts?: number | null,
      GBPMCAAcounts?: number | null,
    } | null,
    USDRateUsed?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};
