/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";
import { BankStatementParserChildren } from "./statementParser";
import { LendingChildren } from "./lendingRoutes";
import { MCAChildren } from "./MCARoutes";
import { KYCChildren } from "./KYCRoutes";
import { AtlasChildren } from "./atlasRoutes";
import { IPChildren } from "./ipRoutes";
import { SettingsChildren } from "./settingsRoutes";
import { KPIChildren } from "./KPIMetricsRoutes";
import { TradeChildren } from "./tradeRoutes";
import { PriceTrackerChildren } from "./priceTrackerRoutes";
import { FinanceChildren } from "./financeRoutes";

const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const SetPassword = lazy(() => import("pages/auth/SetPassword"));
const SetPasswordSuccess = lazy(() => import("pages/auth/SetPassword/SetPasswordSuccess"));
const ResetPasswordResponse = lazy(() => import("pages/auth/ForgotPassword/ResetPasswordResponse"));
const SentConfirmationResponse = lazy(() => import("pages/onboarding/SentConfirmationResponse"));
const CodeVerification = lazy(() => import("pages/onboarding/CodeVerification"));

// CHILDREN ARRAY
export const GeneralChildren = [
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "set-password",
    element: <SetPassword />,
  },
  {
    path: "reset-password-email",
    element: <ResetPasswordResponse />,
  },
  {
    path: "reset-password-success",
    element: <SetPasswordSuccess />,
  },
  // {
  //   path: 'terms-and-conditions',
  //   element: <TermsAndConditions />,
  // },

  // ...ExtraRoutes,
];

export const OnboardingChildren = [
  {
    path: "code-verification",
    element: <CodeVerification />,
  },
  {
    path: "confirmation-email",
    element: <SentConfirmationResponse />,
  },
];

// AUTH CHILDREN ARRAY
export const AuthenticatedGeneralChildren = [
  ...KYCChildren,
  ...MCAChildren,
  ...BankStatementParserChildren,
  ...LendingChildren,
  ...AtlasChildren,
  ...IPChildren,
  ...FinanceChildren,
  ...SettingsChildren,
  ...KPIChildren,
  ...TradeChildren,
  ...PriceTrackerChildren,
];
