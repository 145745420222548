import { createSlice, type PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listContainers,
  listOrders,
  listShipments,
  listEntities,
  createDocument,
  createOrder,
  createShipment,
  updateOrder,
  updateShipment,
  getOrder,
  getShipment,
  updateDocument,
} from "tradeTrackerGraphql/trade-queries";
import { listProducts } from "atlasGraphql/atlas-queries";
import { makeAPI } from "hooks/useAtlasAxios";
import { IProduct, type IEntity } from "interfaces/atlas";
import { type IContainers, type IShipment, type IOrder, type IOrders, type IOrderDocument } from "interfaces/tradeTracker";
import { ErrorHandler } from "helper/Handlers";

interface IFetchOrders {
  listOrders: IOrder[];
}

interface IFetchShipments {
  listShipments: IShipment[];
}

interface IFetchContainers {
  listContainers: IContainers[];
}

interface IFetchEntities {
  listEntities: IEntity[];
}

interface IFetchProducts {
  listProducts: IProduct[];
}

interface ICreateOrders {
  createOrder: IOrder;
}

interface ICreateShipments {
  createShipment: IShipment;
}

interface IUpdateOrders {
  updateOrder: IOrder;
}

interface IUpdateShipments {
  updateShipment: IShipment;
}

interface IFetchSingleOrder {
  getOrder: IOrders | null;
}

interface IFetchSingleShipment {
  getShipment: IShipment | null;
}

interface TradeTrackerState {
  orders: IOrder[];
  ordersBy: IOrder[];
  shipments: IShipment[];
  shipmentsBy: IShipment[];
  containers: IContainers[];
  entities: IEntity[];
  products: IProduct[];
  containersBy: IContainers[];
  loadingShipments: boolean;
  loadingShipmentsBy: boolean;
  loadingContainers: boolean;
  loadingContainersBy: boolean;
  loadingProducts: boolean;
  loadingOrders: boolean;
  selectedOrder: IOrders | null;
  selectedShipment: IShipment | null;
  loading: boolean;
  error: string | undefined;
  status: string | undefined;
}

const initialState: TradeTrackerState = {
  orders: [],
  shipments: [],
  shipmentsBy: [],
  containersBy: [],
  containers: [],
  entities: [],
  products: [],
  ordersBy: [],
  loadingOrders: false,
  loadingShipments: false,
  loadingShipmentsBy: false,
  loadingContainersBy: false,
  loadingContainers: false,
  loadingProducts: false,
  selectedOrder: null,
  selectedShipment: null,
  loading: false,
  error: "",
  status: "",
};

export const fetchEntities = createAsyncThunk("atlas/fetchEntities", async ({ filters }: { filters?: any }) => {
  try {
    const entities = await makeAPI<any, IFetchEntities>({
      query: listEntities,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return entities.data.listEntities || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchOrders = createAsyncThunk("atlas/fetchOrders", async () => {
  try {
    const orders = await makeAPI<any, IFetchOrders>({
      query: listOrders,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });

    return orders?.data?.listOrders || [];
  } catch (err) {
    console.log("fetchOrders err", err);
    return [];
  }
});

export const fetchShipments = createAsyncThunk("atlas/fetchShipments", async () => {
  try {
    const shipments = await makeAPI<any, IFetchShipments>({
      query: listShipments,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return shipments.data.listShipments || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchOrdersBy = createAsyncThunk("atlas/fetchOrdersBy", async ({ filters }: { filters?: any }) => {
  try {
    const orders = await makeAPI<any, IFetchOrders>({
      query: listOrders,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return orders.data?.listOrders || [];
  } catch (err) {
    console.log("fetchOrders by id err", err);
    return [];
  }
});

export const fetchShipmentsBy = createAsyncThunk("atlas/fetchShipmentsBy", async ({ filters }: { filters?: any }) => {
  try {
    const shipments = await makeAPI<any, IFetchShipments>({
      query: listShipments,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });
    return shipments.data?.listShipments || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchContainers = createAsyncThunk("atlas/fetchContainers", async () => {
  try {
    const containers = await makeAPI<any, IFetchContainers>({
      query: listContainers,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return containers.data.listContainers || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchProducts = createAsyncThunk("atlas/fetchProducts", async () => {
  try {
    const products = await makeAPI<any, IFetchProducts>({
      query: listProducts,
      variables: {
        orderBy: { name: "ASC" },
      },
    });
    return products.data.listProducts || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchContainersBy = createAsyncThunk("atlas/fetchContainersBy", async ({ filters }: { filters?: any }) => {
  try {
    const containers = await makeAPI<any, IFetchContainers>({
      query: listContainers,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });
    console.log("containers.data.listContainer", containers.data.listContainers);
    return containers.data.listContainers || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const createOrders = createAsyncThunk("atlas/createOrders", async (data: IOrder) => {
  try {
    const orders = await makeAPI<any, ICreateOrders>({
      query: createOrder,
      variables: {
        data,
      },
    });
    return orders.data.createOrder || null;
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const createShipments = createAsyncThunk("atlas/createShipments", async (data: IShipment) => {
  try {
    const shipments = await makeAPI<any, ICreateShipments>({
      query: createShipment,
      variables: {
        data,
      },
    });
    return shipments.data.createShipment || null;
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const updateOrders = createAsyncThunk("atlas/updateOrders", async (data: IOrder) => {
  try {
    const orders = await makeAPI<any, IUpdateOrders>({
      query: updateOrder,
      variables: {
        data,
      },
    });
    return orders.data.updateOrder || null;
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const fetchSingleOrder = createAsyncThunk("atlas/fetchSingleOrder", async (where: any) => {
  try {
    const order = await makeAPI<any, IFetchSingleOrder>({
      query: getOrder,
      variables: {
        where,
        orderBy: { createdOn: "DESC" },
      },
    });

    return order.data.getOrder;
  } catch (err) {
    console.error("Could not get order:", err);
    ErrorHandler({
      message: "Could not get order",
    });
    return null;
  }
});

export const fetchSingleShipment = createAsyncThunk("atlas/fetchSingleShipment", async (where: any) => {
  try {
    const shipment = await makeAPI<any, IFetchSingleShipment>({
      query: getShipment,
      variables: {
        where,
        orderBy: { createdOn: "DESC" },
      },
    });

    return shipment.data.getShipment || null;
  } catch (err) {
    console.error("Could not get shipment:", err);
    ErrorHandler({
      message: "Could not get shipment",
    });
    return null;
  }
});

export const updateShipments = createAsyncThunk("atlas/updateShipments", async (data: IShipment) => {
  try {
    const shipments = await makeAPI<any, IUpdateShipments>({
      query: updateShipment,
      variables: {
        data,
      },
    });
    console.log("shipments.data.updateShipment", shipments.data.updateShipment);
    return shipments.data.updateShipment;
  } catch (err) {
    console.log(err);
    throw err;
  }
});
export const addDocument = async (data: any) => {
  try {
    const response = await makeAPI<any, { createDocument: IOrderDocument }>({
      query: createDocument,
      variables: {
        data,
      },
    });
    return response;
  } catch (err) {
    console.error("Count not create document:", err);
    ErrorHandler({
      message: "Could not create document",
    });
  }
};

export const editDocument = async (data: any, id: string) => {
  try {
    const response = await makeAPI<any, { updateDocument: IOrderDocument }>({
      query: updateDocument,
      variables: {
        data,
        where: {
          id,
        },
      },
    });
    console.log("response", response);
    return response;
  } catch (err) {
    console.error("Count not update document:", err);
    ErrorHandler({
      message: "Could not update document",
    });
  }
};

const TradeTrackerSlice = createSlice({
  name: "tradeTracker",
  initialState,
  reducers: {
    setAllOrders: (state, action) => {
      state.orders = action.payload.data;
    },
    setSelectedOrder: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.selectedOrder = action.payload.data;
    },
    setSelectedShipment: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.selectedShipment = action.payload.data;
    },
    setEmptyState: (state) => {
      state.loading = false;
      state.error = "";
      state.status = "";
      state.selectedShipment = null;
      state.selectedOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEntities.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchEntities.rejected, (state, action) => {
      state.entities = [...state.entities, ...(action.payload as IEntity[])];
      state.loading = false;
    });
    builder.addCase(fetchOrders.pending, (state) => {
      state.loadingOrders = true;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
      state.loadingOrders = false;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.orders = [...state.orders, ...(action.payload as IOrder[])];
      state.loadingOrders = false;
    });
    // builder.addCase(fetchOrdersBy.pending, (state) => {
    // });
    builder.addCase(fetchOrdersBy.fulfilled, (state, action) => {
      state.ordersBy = action.payload;
    });
    builder.addCase(fetchOrdersBy.rejected, (state, action) => {
      state.ordersBy = [...state.ordersBy, ...(action.payload as IOrder[])];
    });
    builder.addCase(fetchShipments.pending, (state) => {
      state.loadingShipments = true;
    });
    builder.addCase(fetchShipments.fulfilled, (state, action) => {
      state.shipments = action.payload;
      state.loadingShipments = false;
    });
    builder.addCase(fetchShipments.rejected, (state, action) => {
      state.shipments = [...state.shipments, ...(action.payload as IShipment[])];
      state.loadingShipments = false;
    });
    builder.addCase(fetchShipmentsBy.pending, (state) => {
      state.loadingShipmentsBy = true;
    });
    builder.addCase(fetchShipmentsBy.fulfilled, (state, action) => {
      state.shipmentsBy = action.payload;
      state.loadingShipmentsBy = false;
    });
    builder.addCase(fetchShipmentsBy.rejected, (state, action) => {
      state.shipmentsBy = [...state.shipmentsBy, ...(action.payload as IShipment[])];
      state.loadingShipmentsBy = false;
    });
    builder.addCase(fetchContainers.pending, (state) => {
      state.loadingContainers = true;
    });
    builder.addCase(fetchContainers.fulfilled, (state, action) => {
      state.containers = action.payload;
      state.loadingContainers = false;
    });
    builder.addCase(fetchContainers.rejected, (state, action) => {
      state.containersBy = [...state.containersBy, ...(action.payload as IContainers[])];
      state.loadingContainers = false;
    });

    builder.addCase(fetchProducts.pending, (state) => {
      state.loadingProducts = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loadingProducts = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products = [...state.products, ...(action.payload as IProduct[])];
      state.loadingProducts = false;
    });

    builder.addCase(createOrders.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(createOrders.fulfilled, (state, action) => {
      state.selectedOrder = action.payload;
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(createOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = "failed";
    });
    builder.addCase(createShipments.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(createShipments.fulfilled, (state, action) => {
      state.selectedShipment = action.payload;
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(createShipments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = "failed";
    });

    builder.addCase(updateOrders.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(updateOrders.fulfilled, (state, action) => {
      state.selectedOrder = action.payload;
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(updateOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = "failed";
    });
    builder.addCase(updateShipments.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(updateShipments.fulfilled, (state, action) => {
      state.selectedShipment = action.payload;
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(updateShipments.rejected, (state, action) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchContainersBy.pending, (state) => {
      state.loadingContainersBy = true;
    });
    builder.addCase(fetchContainersBy.fulfilled, (state, action) => {
      state.containersBy = action.payload;
      state.loadingContainersBy = false;
    });
    builder.addCase(fetchContainersBy.rejected, (state, action) => {
      state.containersBy = [...state.containersBy, ...(action.payload as IContainers[])];
      state.loadingContainersBy = false;
    });

    builder.addCase(fetchSingleOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingleOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSingleOrder.rejected, (state, action) => {
      state.selectedOrder = null;
      state.loading = false;
    });

    builder.addCase(fetchSingleShipment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingleShipment.fulfilled, (state, action) => {
      state.selectedShipment = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSingleShipment.rejected, (state, action) => {
      state.selectedShipment = null;
      state.loading = false;
    });
  },
});

export const { setAllOrders, setSelectedOrder, setSelectedShipment, setEmptyState } = TradeTrackerSlice.actions;

export default TradeTrackerSlice.reducer;
