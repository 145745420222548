import { type ICampaignMessageStatus } from "./campaignBuilder";
import { type IOrder } from "./tradeTracker";
import { type EntryMode, type IPrices, type ISources, type ProductTypes } from "./price-tracker";
import { FlunaMembers } from "API";

export interface UploadState {
  fileName: string;
  fileUrl: string;
  name: string;
}

export enum EntityTypes {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Startup = "Startup",
  FreightForwarder = "Freight Forwarder",
  TradeAssociation = "Trade Association",
  LocalAgency = "Local Agency",
  NationalAgency = "National Agency",
  InternationalAgency = "International Agency",
}

export interface IEntity {
  id: string;
  testUser: boolean;
  email: string;
  name: string;
  source: string;
  website: string;
  description: string;
  country: string;
  city: string;
  state: string;
  notes: string[];
  address: string;
  street: string;
  postCode: string;
  hubspotId: string;
  exportedBefore: boolean;
  entityType: string;
  tinNumber: string;
  keyContactId: string;
  createdOn: string | Date;
  modifiedOn: string | Date;
  dateIncorporated: string;
  phoneNumber: string;
  certifications: string[];
  isFlunaUser: boolean;
  pastInteraction: boolean;
  documents: IDocument[];
  persons: IPerson[];
  order?: IOrder[];
  supplierSourcingCountries: string[];
  supplierProductExportExperience: Array<{
    id: string;
    name: string;
  }>;
  status?: EntityStatus;
  interactions?: EntityInteraction[];
  customerTags?: Array<{
    id: string;
    name: string;
  }>;
}

export interface IProduct {
  id: string;
  name: string;
  entity?: IEntity[];
  source?: ISources[];
  productType?: ProductTypes;
  price?: IPrices[];
  note?: string;
  author?: string;
  entityMode?: EntryMode;
  createdAt?: string;
  updatedAt?: string;
}

export enum DocCategories {
  KYC = "KYC",
  Financial = "Financial",
  Trade = "Trade",
  Company = "Company",
  Certification = "Certification",
  Order = "Order",
  Shipment = "Shipment",
}

export const DocCertification = {
  GRASP: "GRASP",
  "GLOBAL G.A.P": "Global G.A.P",
  SMETA: "SMETA",
  FDA: "FDA",
  Organics: "Organics",
  Fairtrade: "Fairtrade",
  ISO: "ISO",
  EU: "EU",
  BRC: "BRC",
  "Export certificate": "Export certificate",
};

export interface IDocument {
  id: string;
  entityId: string;
  personId: string;
  tradeId: string;
  orderId?: string;
  shipmentId?: string;
  key: string;
  name: string;
  version?: string;
  gcn?: string;
  expiryDate: string | Date;
  softDelete: boolean;
  issueDate: Date;
  bucketName: string;
  documentTemplateId: string;
  documentTemplate: {
    id: string;
    name: string;
    category: keyof typeof DocCategories;
    documentFields: Array<{
      index: number;
      field: string;
    }>;
  };
  records: Array<{
    index: number;
    field: string;
  }>;
  createdOn: string;
  modifiedOn: string;
}

export interface IDocumentItem {
  name: string;
  value: string;
}

export interface IDocumentFields {
  key: string;
  fileName: string;
  gcn?: string;
  bucketName: string;
  id?: string;
  issueDate?: string | Date;
  softDelete?: boolean;
  expiryDate?: string | Date;
}

export type IDocumentFieldsUpdated = IDocumentItem & {
  documents?: IDocumentFields[];
};

export type TShippingMode = "Land" | "Sea" | "Air";

export interface IPerson {
  id: string;
  entityId: string;
  firstName: string;
  lastName: string;
  gender: string;
  role: string;
  country: string;
  city: string;
  street: string;
  postCode: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  source: string;
  isFlunaUser: boolean;
  isVerified: boolean;
  flunaRegistrationDate: string;
  isDirector: boolean;
  isShareholder: boolean;
  createdOn: string;
  modifiedOn: string;
  entity: IEntity;
  documents: IDocument[];
  campaignMessageStatus: ICampaignMessageStatus[];
  nationality?: string;
}

export interface ITrade {
  id: string;
  tradeName: string;
  buyerName: string;
  supplierName: string;
  productDescription: string;
  destinationCountry: string;
  originCountry: string;
  createdOn: Date;
  amount: number;
  entityId: string;
  // supplierId: string;
  destinationPort: string;
  originPort: string;
  quantityPerContainer: number;
  containerType: string;
  marginInputted: number;
  shippingDurationDate: string | Date;
  rawMaterialCountry: string;
  rawMaterialCostAmount: number;
  rawMaterialCostCurrency: string;
  rawMaterialCostUnit: string;
  rawMaterialPaymentTerms: string;
  materialPreparingCostAmount: number;
  materialPreparingCostCurrency: string;
  materialPreparingCostUnit: string;
  logisticsCostAmount: number;
  logisticsCostCurrency: string;
  logisticsCostUnit: string;
  documentationCostAmount: number;
  documentationCostCurrency: string;
  documentationCostUnit: string;
  customsCostAmount: number;
  customsCostCurrency: string;
  customsCostUnit: string;
  onshoreHandlingCostAmount: number;
  onshoreHandlingCostCurrency: string;
  onshoreHandlingCostUnit: string;
  testingCostAmount: number;
  testingCostCurrency: string;
  testingCostUnit: string;
  supplierOverallCostAmount: number;
  supplierOverallCostCurrency: string;
  supplierOverallCostUnit: string;
  supplierOverallCostIncomeTerms: string[];
  buyerPriceAmount: number;
  buyerPriceCurrency: string;
  buyerPriceUnit: string;
  buyerQuantityTonnes: number;
  buyerPaymentTerms: string;
  buyerIncomeTerms: string;
  freightCostAmount: number;
  freightCostCurrency: string;
  freightCostUnit: string;
  freightForwarderId: string;
  shippingLine: string;
  shippingDurationDaysEstinmate: number;
  shippingDurationDaysActual: number;
  shippingMode: TShippingMode;
  modifiedOn: Date;
  shippingArrivalDate: Date;
}

export interface FormValues {
  name?: string;
  website: string;
  dateIncorporated: string;
  address: string;
  createdOn: string | Date;
  hubspotId: string;
  email: string;
  source: string;
  entityType: string;
  description: string;
  isFlunaUser: boolean;
  pastInteraction: boolean;
  keyContactId: string;
  notes: string;
  country: string;
  products: Array<{
    id: string;
    name: string;
  }>;
  productStatus?: string | null;
  isSupplierProductExportExperience: boolean;
  supplierProductExportExperience: Array<{
    name: string;
    value: boolean;
  }>;
  supplierSourcingCountries: string[];
  certifications: Record<
    string,
    {
      key: string;
      softDelete?: boolean | undefined;
    } & IDocumentItem & {
        documents?: IDocumentFields[] | undefined;
      }
  >;
  customerTags?: Array<{
    id: string;
    name: string;
  }>;
  exportedBefore: boolean;
  flunaRegistrationDate?: string;
  keyContact: "";
  keyContactInfo: {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    nationality: string;
  };
  phoneNumber?: string;
  nationality?: string;
  status?: string;
  clientStatus?: string[];
  // documentCategories: Record<keyof typeof DocCategories, Record<string, IDocumentFieldsUpdated>>;
}

export interface InteractionFormValues {
  author: string;
  authorId: string;
  type: string;
  category: string;
  notes: string;
  media: File[] | InteractionMedia[] | [];
}

export enum EntityStatus {
  diligenceInformationRequired = "diligenceInformationRequired",
  dataSubmissionStarted = "dataSubmissionStarted",
  awaitingOutstandingDocuments = "awaitingOutstandingDocuments",
  documentVerification = "documentVerification",
  preliminaryRiskReview = "preliminaryRiskReview",
  riskDeepDiveCall = "riskDeepDiveCall",
  finalFollowUps = "finalFollowUps",
  creditMemoDrafting = "creditMemoDrafting",
  termSheetNegotiation = "termSheetNegotiation",
  contractInProgress = "contractInProgress",
  readyForDisbursement = "readyForDisbursement",
  readyForCreditCommittee = "readyForCreditCommittee",
  onHold = "onHold",
  cold = "cold",
  closedWon = "closedWon",
  closedLost = "closedLost",
  rejected = "rejected",
}

export interface IFormValues {
  trades: Array<{ deleted: boolean } & ITrade>;
}

export interface StepperNav {
  handleNext: () => void;
  handleBack: () => void;
}

export enum MediaType {
  Image = "Image",
  Video = "Video",
}

export enum InteractionCategory {
  InPersonCommunication = "InPersonCommunication",
  VirtualCommunication = "VirtualCommunication",
  BroadcastActivity = "BroadcastActivity",
}

export enum InteractionType {
  InPersonMeeting = "InPersonMeeting",
  Whatsapp = "Whatsapp",
  SMS = "SMS",
  VirtualMeeting = "VirtualMeeting",
  Email = "Email",
  MarketingEmail = "MarketingEmail",
  EmailCampaign = "EmailCampaign",
}
export interface EntityInteraction {
  id: string;
  type: InteractionType;
  category?: InteractionCategory;
  authorId?: string;
  media?: File[] | InteractionMedia[] | any[];
  entityId?: string;
  entity?: IEntity;
  author?: FlunaMembers;
  notes: string;
  createdOn: string;
  modifiedOn?: string;
}

export enum InteractionMediaType {
  Image = "Image",
  Video = "Video",
}

export interface InteractionMedia {
  id: string;
  name: string;
  thumbnail?: string;
  type: InteractionMediaType;
  bucketName: string;
  key: string;
  mediaUrl?: string;
  interactionId?: string;
  interaction?: EntityInteraction;
  createdOn: string;
  modifiedOn: string;
}
