/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { listDocuments, listEntities, listPeople, listProducts, listTrades } from "atlasGraphql/atlas-queries";
import { makeAPI } from "hooks/useAtlasAxios";
import type { ITrade, IEntity, IProduct, IDocument, IPerson, EntityInteraction } from "interfaces/atlas";

interface EntitiesState {
  entities: IEntity[];
  selectedEntity: IEntity | null;
  trades: ITrade[];
  documents: IDocument[];
  products: any[];
  productsAll: any[];
  people: IPerson[];
  loading: boolean;
  loadingProducts: boolean;
  entitiesCount: number;
  peopleCount: number;
  productsCount: number;
  interactions: EntityInteraction[];
  selectedInteraction: EntityInteraction | null;
}

interface IFetchEntities {
  listEntities: IEntity[];
}

interface IFetchPersons {
  listPeople: IPerson[];
}

interface IFetchTrades {
  listTrades: ITrade[];
}

interface IFetchProducts {
  listProducts: IProduct[];
}

interface IFetchDocuments {
  listDocuments: IDocument[];
}

const initialState: EntitiesState = {
  entities: [],
  selectedEntity: null,
  products: [],
  productsAll: [],
  documents: [],
  people: [],
  trades: [],
  loadingProducts: false,
  entitiesCount: 0,
  peopleCount: 0,
  productsCount: 0,
  loading: false,
  interactions: [],
  selectedInteraction: null,
};

export const fetchEntities = createAsyncThunk(
  "atlas/fetchEntities",
  async ({ rowsPerPage, page, filters }: { rowsPerPage: number; page: number; filters?: any }) => {
    try {
      const entities = await makeAPI<any, IFetchEntities>({
        query: listEntities,
        variables: {
          orderBy: { createdOn: "DESC" },
          skip: page * rowsPerPage,
          take: rowsPerPage,
          where: filters?.where || {},
        },
      });
      console.log("entities", entities);
      return entities?.data?.listEntities;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
);

export const fetchPersons = createAsyncThunk(
  "atlas/fetchPersons",
  async ({ rowsPerPage, page, filters }: { rowsPerPage: number; page: number; filters?: any }) => {
    try {
      const entities = await makeAPI<any, IFetchPersons>({
        query: listPeople,
        variables: {
          orderBy: { createdOn: "DESC" },
          skip: page * rowsPerPage,
          take: rowsPerPage,
          where: filters?.where || {},
        },
      });
      return entities.data.listPeople;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
);

export const fetchTrades = createAsyncThunk(
  "atlas/fetchTrades",
  async ({ rowsPerPage, page, filters }: { rowsPerPage?: number; page?: number; filters?: any }) => {
    try {
      const trades = await makeAPI<any, IFetchTrades>({
        query: listTrades,
        variables: {
          orderBy: {
            createdOn: "DESC",
          },
          // skip: page * rowsPerPage,
          // take: rowsPerPage,
          where: filters?.where || {},
        },
      });

      return trades.data.listTrades;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
);

export const fetchProducts = createAsyncThunk(
  "atlas/fetchProducts",
  async ({ rowsPerPage, page, filters }: { rowsPerPage: number; page: number; filters?: any }) => {
    try {
      const products = await makeAPI<any, IFetchProducts>({
        query: listProducts,
        variables: {
          orderBy: { name: "ASC" },
          skip: page * rowsPerPage,
          take: rowsPerPage,
          where: filters?.where || {},
        },
      });
      return products.data.listProducts;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
);

export const fetchProductsAll = createAsyncThunk("atlas/fetchProductsAll", async () => {
  try {
    const products = await makeAPI<any, IFetchProducts>({
      query: listProducts,
      variables: {
        orderBy: { name: "ASC" },
        skip: 0,
        take: 100000000,
        where: {},
      },
    });
    return products.data.listProducts;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchDocuments = createAsyncThunk(
  "atlas/fetchDocuments",
  async ({ rowsPerPage, page, filters }: { rowsPerPage?: number; page?: number; filters?: any }) => {
    try {
      const documents = await makeAPI<any, IFetchDocuments>({
        query: listDocuments,
        variables: {
          orderBy: { name: "ASC" },
          where: filters?.where || {},
        },
      });
      return documents.data.listDocuments;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
);

const atlasSlice = createSlice({
  name: "atlas",
  initialState,
  reducers: {
    setEntities: (
      state,
      action: PayloadAction<{
        data: IEntity[];
      }>,
    ) => {
      state.entities = action.payload.data;
    },
    setSelectedEntity: (state, action: PayloadAction<IEntity>) => {
      state.selectedEntity = action.payload;
    },
    setEntitiesCount: (state, action: PayloadAction<number>) => {
      state.entitiesCount = action.payload;
    },
    setPeopleCount: (state, action: PayloadAction<number>) => {
      state.peopleCount = action.payload;
    },
    setProducts: (
      state,
      action: PayloadAction<{
        data: IProduct[];
      }>,
    ) => {
      state.products = action.payload.data;
    },
    setProductsCount: (state, action: PayloadAction<number>) => {
      state.productsCount = action.payload;
    },
    clearTrades: (state, action: PayloadAction<number>) => {
      state.productsCount = action.payload;
    },
    setInteractions: (state, action: PayloadAction<EntityInteraction[]>) => {
      state.interactions = action.payload;
    },
    setSelectedInteraction: (state, action: PayloadAction<EntityInteraction | null>) => {
      console.log(action.payload);
      state.selectedInteraction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEntities.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchEntities.rejected, (state, action) => {
      state.entities = [...state.entities, ...(action.payload as IEntity[])];
      state.loading = false;
    });
    builder.addCase(fetchPersons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPersons.fulfilled, (state, action) => {
      state.people = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchPersons.rejected, (state, action) => {
      state.people = [...state.people, ...(action.payload as IPerson[])];
      state.loading = false;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload || [];
      state.loadingProducts = false;
    });
    builder.addCase(fetchProductsAll.fulfilled, (state, action) => {
      state.productsAll = action.payload;
      state.loadingProducts = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loadingProducts = false;
    });
    builder.addCase(fetchTrades.fulfilled, (state, action) => {
      state.trades = action.payload;
    });
    builder.addCase(fetchTrades.rejected, (state, action) => {
      state.trades = [...state.trades, ...(action.payload as ITrade[])];
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      state.documents = action.payload;
    });
    builder.addCase(fetchDocuments.rejected, (state, action) => {
      state.documents = [...state.documents, ...(action.payload as IDocument[])];
    });
  },
});

export const { setEntities, setSelectedEntity, setEntitiesCount, setPeopleCount, setProducts, setProductsCount, setInteractions, setSelectedInteraction } =
  atlasSlice.actions;

export default atlasSlice.reducer;
